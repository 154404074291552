@import './abstracts/variables';
@import './abstracts/functions';
@import './abstracts/mixins';
// @import './utils/placeholders';

@import './vendors/swiper';
@import './vendors/glightbox';
@import './vendors/react-day-picker';
// @import './vendors/rc-slider';

@import './base/reset';
// @import './base/main';
// @import './base/fonts';
// @import './base/typography';
// @import './base/helpers';
// @import './base/animations';

@import './layout/overall';
@import './layout/grid';
@import './layout/header';
@import './layout/footer';
// @import './layout/sidebar';
// @import './layout/forms';

@import './components/hero';
@import './components/about';
@import './components/advantages';
@import './components/food';
@import './components/nutrition';
@import './components/pool';
@import './components/entertainment';
@import './components/beach';
@import './components/rooms';
@import './components/children';
@import './components/stocks';
@import './components/quiz';
@import './components/reviews';
@import './components/contacts';
@import './components/map';
@import './components/question';

// @import './pages/home';

// @import './themes/default';
// @import './themes/admin';

td {
  padding: 10px;

  border: 1px solid #e5e5e5;
}
