.beach {
  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 61px;
  }

  &__first {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: -0.04em;
    text-align: center;
  }

  &__text {
    font-size: 1.8rem;
    line-height: 1.315;
    text-align: center;
  }

  &__text p:not(:last-child) {
    margin-bottom: 16px;
  }

  &__slider {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__slide {
    display: flex;
    padding-bottom: 93%;
  }

  &__slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(45deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(-135deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  @include media(md) {
    &__content {
      padding-bottom: 100px;
    }

    &__first {
      justify-content: center;
    }

    &__title {
      font-size: 5.2rem;
      line-height: 1.08;
    }

    &__slider {
      margin: 0;
    }

    &__slide {
      padding-bottom: 65%;
    }
  }

  @include media(xl) {
    &__content {
      flex-direction: row;
      padding-bottom: 149px;
    }

    &__first {
      order: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-left: 30px;
    }

    &__title {
      max-width: 400px;

      font-size: 6.8rem;
      align-self: center;
    }

    &__second {
      flex-shrink: 0;
      width: 680px;
    }

    &__slide {
      padding-bottom: 664px;
    }

    &__prev {
      left: 1rem;

      &::before {
        left: 70%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }

    &__next {
      right: 1rem;

      &::before {
        left: 30%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
}
