*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;

  /* font-size: 100%; */
  font: inherit;
  /* vertical-align: baseline; */

  /* border: 0; */
}

html {
  /* font-size: 10px; */
  font-size: 62.5%; /* 10px / 16px = 0.625 = 62.5% percentage of user's browser font-size */
  /* example:
  p {
    font-size: 1.4rem; will be 14px
  }
  */
}

body {
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1;
}

img {
  max-width: 100%;
}

/* ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after {
  content: '';
  content: none;
}

q::before,
q::after {
  content: '';
  content: none;
}*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}
