.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;

  background-color: #fff;

  &__content {
    position: relative;

    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;

    &::before {
      width: 2.6rem;
      height: 1px;

      background-color: map-get($colors, 'main');

      content: '';
    }

    span {
      width: 2.6rem;
      height: 1px;
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;

      background-color: map-get($colors, 'main');
    }

    &::after {
      width: 26px;
      height: 1px;

      background-color: map-get($colors, 'main');

      content: '';
    }
  }

  &__nav {
    display: none;

    a {
      transition: 0.2s;

      &:hover {
        color: map-get($colors, main);
      }
    }
  }

  &__logo {
    width: 13.4rem;
    margin-right: auto;
  }

  &__phone {
    font-size: 1.2rem;
    text-decoration: none;

    color: map-get($colors, 'main');

    transition: 0.2s;

    &:hover {
      color: darken(map-get($colors, main), 10%);
    }
  }

  &__callback {
    display: none;
  }

  &_opened &__button {
    position: relative;
    z-index: 1010;

    &::before {
      position: relative;
      top: 1px;
      transform: rotate(45deg);
    }

    span {
      display: none;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &_opened &__nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 7.9rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;

    background-color: rgba(255, 255, 255, 0.97);

    a {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;

      font-size: 2rem;
      font-weight: 600;
      line-height: 1.25;
      text-decoration: none;

      color: #000;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(178, 137, 117, 0.3);
      }
    }
  }

  @include media(md) {
    &__content {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &__button {
      width: 5.6rem;
      margin-right: 6.2rem;

      &::before {
        width: 5.6rem;
      }

      span {
        width: 5.6rem;
        margin-top: 11px;
        margin-bottom: 11px;
      }

      &::after {
        width: 5.6rem;
      }
    }

    &__logo {
      width: 22rem;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__phone {
      margin-right: 2.1rem;

      font-size: 2rem;
      font-weight: 700;
    }

    &__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15.8rem;
      height: 4.4rem;

      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;

      border: 1px solid map-get($colors, main);
      color: map-get($colors, main);
      background-color: transparent;

      transition: 0.2s;

      &:hover {
        color: #fff;
        background-color: map-get($colors, main);
      }
    }

    &_opened &__button {
      &::before {
        top: 2px;

        width: 36px;
        height: 2px;
      }

      &::after {
        width: 36px;
        height: 2px;
      }
    }

    &_opened &__nav {
      position: absolute;
      width: 37rem;
      height: auto;
      padding-top: 14.4rem;
      padding-bottom: 7.1rem;
    }
  }

  @include media(xl) {
    &__content {
      justify-content: space-between;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &__button {
      display: none;
    }

    &__nav {
      display: flex;
      column-gap: 5.5rem;

      a {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.25;
        text-decoration: none;

        color: #000;
      }
    }

    &__logo {
      margin-right: 0;
    }

    &__phone {
      margin-right: 2.8rem;
    }

    &__callback {
      width: 18rem;
      height: 5.7rem;
    }
  }
}
